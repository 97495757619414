const HOST = window.location.host;
const API_ENDPOINT = "https://" + HOST + "/api";
const REDIRECT_ENDPOINT = "https://" + HOST + "/redirect";

/*if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    API_HOST = "https://localhost:7246/api";
} else {
    API_HOST = "https://" + HOST + "/api";
}
*/
export class Api {
    static userProfile() {
        return API_ENDPOINT + "/user";
    }

    static isUserOnline() {
        return API_ENDPOINT + "/user/isonline";
    }

    static serverStatus() {
        return API_ENDPOINT + "/serverstatus";
    }

    static setWeapon(weaponClass, skinId, seedId, weaponFloat, weaponName) {
        return API_ENDPOINT + `/setweapon?weaponClass=${weaponClass}&skinId=${skinId}&seedId=${seedId}&weaponFloat=${weaponFloat}&weaponName=${weaponName}`;
    }
}

export class Redirect {
    static steamLogin() {
        return REDIRECT_ENDPOINT + "/steamlogin";
    }
}
