import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Text,
    Box,
    Card,
    CardBody,
    Tag,
    FormControl,
    HStack,
    Input,
    Link,
    Image,
    Grid,
    GridItem,
    Stack,
    useBreakpointValue,
} from '@chakra-ui/react'
import { Button, ButtonGroup } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { useContext, useEffect, useState } from 'react';
import UserContext from '../contexts/usercontext';
import { Api } from '../config';

export function SkinTestServerList() {
    const user = useContext(UserContext);
    const [serverStatus, setServerStatus] = useState(null);
    const [weaponName, setWeaponName] = useState("");
    const [skinId, setSkinId] = useState("");
    const [seedId, setSeedId] = useState("");
    const [weaponFloat, setWeaponFloat] = useState("");
    const [skinIdError, setSkinIdError] = useState(false);
    const [seedIdError, setSeedIdError] = useState(false);
    const [weaponFloatError, setWeaponFloatError] = useState(false);

    useEffect(() => {
        fetch(Api.serverStatus())
            .then(res => res.json())
            .then(
                (result) => {
                    setServerStatus(result);
                },
                (error) => {
                }
            )
    }, []);

    const handleSetWeapon = () => {
        const skinIdInt = parseInt(skinId);
        if (skinIdInt == NaN) {
            setSkinIdError(true);
        } else {
            setSkinIdError(false);
        }

        const seedIdInt = parseInt(seedId);
        if (seedIdInt == NaN) {
            setSeedIdError(true);
        } else {
            setSeedIdError(false);
        }

        const weaponFloatFloat = parseFloat(weaponFloat);
        if (weaponFloatFloat == NaN) {
            setWeaponFloatError(true);
        } else {
            setWeaponFloatError(false);
        }

        if (skinIdInt != NaN && seedIdInt != NaN && weaponFloatFloat != NaN) {
            fetch(Api.setWeapon(weaponName, skinIdInt, seedIdInt, weaponFloatFloat))
                .then(res => res.json())
                .then(
                    (result) => {
                    },
                    (error) => {
                    }
                )
        }
    };

    const serverListSpan = useBreakpointValue(
        {
            base: 10,
            md: 6,
        }
    )

    const sideBarSpan = useBreakpointValue(
        {
            base: 10,
            md: 4,
        }
    )

    return (
        <>
            <Grid templateColumns='repeat(10, 1fr)' gap={4}>
                <GridItem colSpan={serverListSpan}>
                    <TableContainer>
                        <Table variant='simple'>
                            <TableCaption>服务器列表</TableCaption>
                            <Thead>
                                <Tr>
                                    <Th style={{ width: '1px' }}></Th>
                                    <Th>服务器</Th>
                                    <Th>地图</Th>
                                    <Th>人数</Th>
                                </Tr>
                            </Thead>
                            <Tbody fontSize="sm">
                                <Tr>
                                    <Td>
                                        <Button as="a" size='sm' colorScheme='blue' href="steam://connect/47.92.66.194:27015">进入房间</Button></Td>
                                    <Td>皮肤检视 #1</Td>
                                    <Td>{serverStatus ? serverStatus.map : ''}</Td>
                                    <Td>{serverStatus ? `${serverStatus.players}/${serverStatus.maxPlayers}` : ""}</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </TableContainer>
                </GridItem>
                <GridItem colSpan={sideBarSpan} >
                    <Card variant='outline' bg="#fafafa">
                        <CardBody>
                            <Stack>
                                <Text fontSize='sm'>进入社区服输入 .wx ，扫描二维码打开小程序，即刻体验饰品快速检视！</Text>
                                <Text fontSize='sm'>支持 BUFF/UU 饰品链接解析~</Text>
                                <Box maxWidth="500px">
                                    <Image src='/wxmp.png' />
                                </Box>
                                <Text fontSize='sm'>欢迎加群 716743351 交流反馈使用问题。</Text>
                                {user ?
                                    <HStack spacing='24px' style={{ display: 'none' }}>
                                        <FormControl>
                                            <Input size="sm" placeholder='武器类型' onChange={(e) => setWeaponName(e.target.value)} />
                                        </FormControl>
                                        <FormControl isInvalid={skinIdError}>
                                            <Input size="sm" placeholder='皮肤编号' onChange={(e) => setSkinId(e.target.value)} />
                                        </FormControl>
                                        <FormControl isInvalid={seedIdError}>
                                            <Input size="sm" placeholder='模板编号' onChange={(e) => setSeedId(e.target.value)} />
                                        </FormControl>
                                        <FormControl isInvalid={weaponFloatError}>
                                            <Input size="sm" placeholder='武器磨损' onChange={(e) => setWeaponFloat(e.target.value)} />
                                        </FormControl>
                                        <Button size="sm" onClick={handleSetWeapon}>应用</Button>
                                    </HStack>
                                    : <></>}
                            </Stack>
                        </CardBody>
                    </Card>
                </GridItem>
            </Grid>
        </>
    );
}

