import { useState, useEffect } from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { SkinTestServerList } from "./skintestserverlist";

export default function ServerListTabs(props) {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (<div>
        <Tabs>
            <TabList>
                <Tab>皮肤检视</Tab>
            </TabList>

            <TabPanels>
                <TabPanel>
                    <SkinTestServerList />
                </TabPanel>
            </TabPanels>
        </Tabs>
    </div>);
}