import React from "react";
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import ResponsiveNavBar from "./components/navbar";
import { Api } from "./config";
import { UserContextProvider } from "./contexts/usercontext";
import ApplyWeapon from "./pages/applyweapon";
import Extensions from "./pages/extensions";
import Homepage from "./pages/homepage";

export default function Layout(props) {
    const [user, setUser] = React.useState(null);
    React.useEffect(() => {
        fetch(Api.userProfile())
            .then(res => res.json())
            .then(
                (result) => {
                    setUser(result);
                },
                (error) => {
                    setUser(null);
                }
            )
    }, []);

    return <UserContextProvider value={user}>
        <BrowserRouter>
            <ResponsiveNavBar></ResponsiveNavBar>
            <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path="/applyweapon" element={<ApplyWeapon />} />
                <Route path="/extensions" element={<Extensions />} />
            </Routes>
        </BrowserRouter>
    </UserContextProvider>
}