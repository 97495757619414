import { Container } from '@chakra-ui/react'
import ServerListTabs from "../components/serverlisttabs";

export default function Homepage() {
    return (<>
        <Container maxW="container.lg">
            <ServerListTabs />
        </Container>
    </>);
}
