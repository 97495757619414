import { Container } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import UserContext from "../contexts/usercontext";
import { useSearchParams } from "react-router-dom";
import { Api } from "../config";
import { Helmet } from "react-helmet";

export default function ApplyWeapon(props) {
    const user = useContext(UserContext);
    let [searchParams, setSearchParams] = useSearchParams();
    let [userOnline, setUserOnline] = useState(null);

    let [clientLaunched, setClientLaunched] = useState(false);

    let [weaponDone, setWeaponDone] = useState(false);
    let [success, setSuccess] = useState(false);

    const checkUserOnline = () => {
        if (user) {
            fetch(Api.isUserOnline())
                .then(res => res.json())
                .then(
                    (result) => {
                        setUserOnline(result.isOnline);
                    },
                    (error) => {
                        setUserOnline(false);
                    }
                );
        }
    };

    useEffect(checkUserOnline, [user]);

    useEffect(() => {
        if (userOnline != null) {
            const interval = setInterval(checkUserOnline, 3000);
            if (userOnline) {
                clearInterval(interval);

                const timeout = 0; //clientLaunched ? 5000 : 0;
                setTimeout(() => {
                    fetch(Api.setWeapon(searchParams.get('weaponClass'),
                        searchParams.get('skinId'),
                        searchParams.get('seedId'),
                        searchParams.get('weaponFloat'),
                        searchParams.get('weaponName')))
                        .then(res => res.ok)
                        .then(
                            (result) => {
                                console.log(result);
                                setWeaponDone(true);
                                setSuccess(result);
                            },
                            (error) => {
                                console.log(error);
                                setWeaponDone(true);
                                setSuccess(false);
                            }
                        );
                }, timeout);
            } else {
                if (!clientLaunched) {
                    window.location = "steam://connect/47.92.66.194:27015";
                    setClientLaunched(true);
                }
            }

            return () => clearInterval(interval);
        }
    }, [userOnline]);

    useEffect(() => {
        if (success) {
            setTimeout(() => window.close(), 3000);
        }
    }, [success])

    if (!user) {
        return (<></>)
    }
    else {
        return (<>
            <Helmet>
                <title>正在应用皮肤设置 | DEagle Club</title>
            </Helmet>
            <Container maxW="900px">
                {weaponDone ? (success ? <>设置成功，本页面将在3秒后关闭</> : <>设置失败！</>) : (userOnline ? <>正在应用皮肤设置…</> : <>正在启动客户端等待玩家连接…</>)}
            </Container>
        </>);
    }
}
