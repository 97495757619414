import { Box, Card, CardBody, Container, Heading, HStack, Image, Link, List, ListItem, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, UnorderedList } from '@chakra-ui/react'
import { Helmet } from 'react-helmet';

export default function Extensions() {
    return (<>
        <Helmet>
            <title>浏览器插件安装说明 | DEagle Club</title>
        </Helmet>
        <Container maxW="900px">
            <Stack mb="100px" spacing="20px">
                <Heading size='lg'>一键检视 Buff/UU 在售商品</Heading>
                <Card variant='outline' bg="#fafafa">
                    <CardBody>
                        <Text fontSize='sm'>欢迎加群 716743351 交流反馈使用问题。</Text>
                    </CardBody>
                </Card>
                <Tabs>
                    <TabList>
                        <Tab>浏览器插件安装说明</Tab>
                        <Tab>手动安装教程</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <Stack mb="100px" spacing="20px">
                                <Text>1. 在浏览器中安装 DEagle 社区服检视插件。</Text>
                                <List>
                                    <Box>Edge
                                        <UnorderedList fontSize="sm" ml="50px">
                                            <ListItem>
                                                <Link color="teal" href="https://chrome.google.com/webstore/detail/deagle-%E7%A4%BE%E5%8C%BA%E6%9C%8D%E6%A3%80%E8%A7%86%E6%8F%92%E4%BB%B6/eakbopgckefjfbnphjebgodamlmmjhml">访问 Chrome 扩展商店页面</Link>
                                            </ListItem>
                                            <ListItem>
                                                或 <Link color="teal" href="/deagle_chrome_edge.zip">点击此处</Link> 下载插件手动安装
                                            </ListItem>
                                        </UnorderedList>
                                    </Box>
                                    <Box>Chrome
                                        <UnorderedList fontSize="sm" ml="50px">
                                            <ListItem>
                                                <Link color="teal" href="https://chrome.google.com/webstore/detail/deagle-%E7%A4%BE%E5%8C%BA%E6%9C%8D%E6%A3%80%E8%A7%86%E6%8F%92%E4%BB%B6/eakbopgckefjfbnphjebgodamlmmjhml">访问 Chrome 扩展商店页面</Link>
                                            </ListItem>
                                            <ListItem>
                                                或 <Link color="teal" href="/deagle_chrome_edge.zip">点击此处</Link> 下载插件手动安装
                                            </ListItem>
                                        </UnorderedList>
                                    </Box>
                                    <Box>FireFox
                                        <UnorderedList fontSize="sm" ml="50px">
                                            <ListItem>
                                                <Link color="teal" href="https://addons.mozilla.org/zh-CN/firefox/addon/deagle-%E7%A4%BE%E5%8C%BA%E6%9C%8D%E6%A3%80%E8%A7%86%E6%8F%92%E4%BB%B6/">访问 Firefox 扩展商店页面</Link>
                                            </ListItem>
                                            <ListItem>
                                                <Link color="teal" href="/deagle_firefox.zip">点击此处</Link>下载插件手动安装
                                            </ListItem>
                                        </UnorderedList>
                                    </Box>
                                </List>
                                <Text>2. 安装后访问 Buff 或悠悠有品，在商品页面即会出现 “DEagle 社区服检视” 按钮。点击即可一键检视。</Text>
                                <Text>Buff 按钮样式：</Text>
                                <Box maxWidth="500px">
                                    <Image src='/deagle.jpg' />
                                </Box>
                                <Text>悠悠有品按钮样式（需要鼠标悬停在商品缩略图上）：</Text>
                                <Image src='/uu.png' />
                            </Stack>
                        </TabPanel>
                        <TabPanel>
                            <Tabs>
                                <TabList>
                                    <Tab>Edge / Chrome</Tab>
                                    <Tab>Firefox</Tab>
                                </TabList>
                                <TabPanels>
                                    <TabPanel>
                                        <Stack mb="100px" spacing="20px">
                                            <Text>1. 手动下载后请解压压缩包。</Text>
                                            <Text>2 在 Edge 或 Chrome 浏览器的应用扩展界面，选择“启用开发者模式”，并选择“加载解压缩的扩展”。在弹出的对话框中，选择刚才解压好的文件夹，并点击确定。</Text>
                                            <Box>
                                                <Image src='/install_extension.png' />
                                            </Box>
                                        </Stack>
                                    </TabPanel>
                                    <TabPanel>
                                        <Stack mb="100px" spacing="20px">
                                            <Text>在 Firefox 的地址栏中输入 <pre style={{ display: 'inline' }}>about:debugging</pre>，左侧选择“此 Firefox”，再选择“加载临时插件”。在弹出的对话框中，选择下载好的压缩包即可。</Text>
                                            <Box>
                                                <Image src='/firefox_install.png' />
                                            </Box>
                                        </Stack>
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Stack>
        </Container>
    </>);
}
