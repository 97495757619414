import logo from './logo.svg';
import './App.css';
import { Api } from './config';
import Layout from './layout';

function App() {
  return <Layout />;
}

export default App;
