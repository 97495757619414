import {
    Box,
    Button,
    ButtonGroup,
    Container,
    Flex,
    HStack,
    IconButton,
    Tag,
    useBreakpointValue,
    useColorModeValue,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSteam } from '@fortawesome/free-brands-svg-icons'
import * as React from 'react'
import UserContext from '../contexts/usercontext'

export default function ResponsiveNavBar() {
    const isDesktop = useBreakpointValue({ base: false, lg: true })
    const user = React.useContext(UserContext)
    return (
        <Box as="section" pb={{ base: '12' }}>
            <Box as="nav" bg="bg-surface" boxShadow={useColorModeValue('sm', 'sm-dark')}>
                <Container maxW='container.lg' py={{ base: '2' }}>
                    <HStack spacing="10" justify="space-between">
                        <Button as='a' variant="ghost" href="/"><img sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} style={{ width: '32px', marginRight: '10px' }} src="/logo512.png" />DEagle Club</Button>
                        {isDesktop ? (
                            <Flex justify="space-between" flex="1">
                                <ButtonGroup variant="link" spacing="8">
                                    <Button as="a" href="/extensions">浏览器插件</Button>
                                    <Button as="a" href="https://uuzs.top" target="_blank" title="悠悠有品历史价格走势">悠悠助手</Button>
                                </ButtonGroup>
                                <HStack spacing="3">
                                    {
                                        !user
                                            ? <Button as="a" variant="solid" href="/redirect/steamlogin?redirectUri=/">
                                                <FontAwesomeIcon icon={faSteam} />&nbsp; Steam 账户登录
                                            </Button>
                                            : <>
                                                <Tag size="md" variant='solid' colorScheme='teal'>
                                                    {user.nickName}
                                                </Tag>
                                                <Button as="a" variant="solid" href="/redirect/logout">
                                                    退出登录
                                                </Button>
                                            </>
                                    }
                                </HStack>
                            </Flex>
                        ) : (
                            <IconButton
                                variant="ghost"
                                aria-label="Open Menu"
                            />
                        )}
                    </HStack>
                </Container>
            </Box>
        </Box>
    )
}